<template>
    <div class="all-box">
        <div class="all-main-box-all" v-show="!allLoading">
            <div class="top-box">
                <div class="top-lis top-lis-ts1">
                    <span class="span-title">加班员工</span>
                    <el-input v-model="inputVal1" class="input-box" clearable placeholder="请输入姓名" @input="inputHandlerUserName"></el-input>
                </div>
                <div class="top-lis top-lis-ts2">
                    <span class="span-title">员工工号</span>
                    <el-input v-model="inputVal2" class="input-box" clearable placeholder="请输入工号" @input="inputHandlerJobNumber"></el-input>
                </div>
                <div class="top-lis top-lis-ts2">
                    <span class="span-title">流程ID</span>
                    <el-input v-model="inputValID" class="input-box" clearable placeholder="请输入流程ID" @input="inputHandlerJobNumberID"></el-input>
                </div>
                <div class="top-lis top-lis-ts3">
                    <span class="span-title">加班开始日期</span>
                    <el-date-picker class="date-box" v-model="dateVal" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="changeHandlerDate"></el-date-picker>
                </div>
                <div class="top-lis top-lis-ts4">
                    <span class="span-title">审批状态</span>
                    <el-select v-model="selectVal"  placeholder="请选择" clearable class="search-ipt"  @change="stateChangeHandler">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </div>
                <!-- <div class="send-box" v-show="showAdmin"  @click="clickSend">全量发起</div> -->
                <div class="export-box" v-show="showAdmin" @click="clickExport">导出</div>
            </div>
            <div class="bom-box">
                <div class="head-box">
                    <div class="head-lis head-lis1">加班人</div>
                    <div class="head-lis head-lis1s">员工工号</div>
                    <div class="head-lis head-lis2">加班原因</div>
                    <div class="head-lis head-lis3">开始时间</div>
                    <div class="head-lis head-lis4">结束时间</div>
                    <div class="head-lis head-lis5">加班时长</div>
                    <div class="head-lis head-lis6">加班备注</div>
                    <div class="head-lis head-lis7">审批状态</div>
                </div>
                <div class="list-box" v-show="!showLoading">
                    <div class="list-main-box"  v-show="!showKong">
                        <div class="list-main-table-box">
                            <div class="list-lis-box" v-for="(item,index) in dataList" :key="index" @click="clickLis(item)">
                                <div class="lsi-box-son son-ss1">{{item.jiaBanRenName}}</div>
                                <div class="lsi-box-son son-ss1s">{{item.jobnumber}}</div>
                                <div class="lsi-box-son son-ss2">
                                    <span>{{item.jiaBanReason}}</span>
                                </div>
                                <div class="lsi-box-son son-ss3">{{item.jiaBanStartDate}}</div>
                                <div class="lsi-box-son son-ss4">{{item.jiaBanEndDate}}</div>
                                <div class="lsi-box-son son-ss5">{{item.jiaBanShiChang}}</div>
                                <div class="lsi-box-son son-ss6">
                                    <span>{{item.workNode}}</span>
                                </div>
                                <div class="lsi-box-son son-ss7">
                                    <span :class="['span-status',{'daishenpi':item.checkState === '待审批'},{'tongguo':item.checkState === '通过'},{'butongguo':item.checkState === '不通过'},{'bohui':item.checkState === '驳回'},{'jinxingzhong':item.checkState === '进行中'}]">{{item.checkState}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="table-fenye">
                            <el-pagination class="fenye-box"  @size-change="handleSizeChange" :page-sizes="[20, 50, 100, 200]" :page-size="pageSize" @current-change="handleCurrentChange" :current-page="currentPage"  layout="total,sizes,prev, pager, next, jumper" :total="total"></el-pagination>
                        </div>
                        
                    </div>
                    <div class="kong-box" v-show="showKong">
                        <img src="../assets/imgs/zanwushuju.png" alt="" class="img-kong" >
                        <p>暂无数据 </p>
                    </div>
                </div>
                <div class="loading-box" v-show="showLoading">
                    <div class="content" >
                        <div class="point1"></div>
                        <div class="point2"></div>
                        <div class="point3"></div>
                    </div>
                </div>
            </div>
            <el-drawer class="tanchu-box" title="详情"  :destroyOnClose="true" :visible="showDrawer" :direction="direction" :before-close="handleCloseDrawer">
                <div class="draw-main">
                    <iframe :src="`https://yrkq.yurun.com/#/workOverState?id=${this.choseObj.businessId}`" frameborder="0"></iframe>
                </div>
            </el-drawer>
        </div>
        <div class="all-loading-box" v-show="allLoading">
            <div class="content" >
                <div class="point1"></div>
                <div class="point2"></div>
                <div class="point3"></div>
            </div>
        </div>
        
    </div>
</template>
        
<script>
import imgTianxie from "../assets/imgs/tianxie-img.png"
import imgSuccess from "../assets/imgs/img-duihao-last.png"
import imgLoading from "../assets/imgs/jinxgzhong-img.png"
import imgCuowu from "../assets/imgs/cuowu-img.png"
import * as api from "../api"
import * as dd from 'dingtalk-jsapi'; // 此方式为整体加载，也可按需进行加载
import axios from "axios"
export default {
   data() {
    return {
        
        selectVal:'',
        options:[
            {value: '进行中',label: '进行中'},
            {value: '待审批',label: '待审批'},
            {value: '通过',label: '通过'},
            {value: '不通过',label: '不通过'},
            {value: '驳回',label: '驳回'},
        ],

        dateVal:'',
        numVal:null,


        showLoading:true,
        showKong:false,

        pageSize:20,
        total:0,   
        currentPage:1, 
        id:'',
        dataList:[],

        showDrawer: false,
        direction: 'rtl',
        infoObj:{},
        choseObj:{},
        liuchengList:[],

        inputVal1:'',
        inputVal2:'',
        inputValID:'',


        allLoading:true,

        tuchengLoading:true,

        startTime:'',
        endTime:'',

        state:'',


        showAdmin:false,


    }
   },
   mounted () {
        this.getLoginInfo()
   },
   methods: {
        clickSend(){
            this.showLoading = true
            api.getSendOverApi().then(res=>{
                console.log(res.data);
                if(res){
                    this.showLoading = false
                    if(res.data.data === "操作成功"){
                        this.$message.success('手动推送进行中的加班待办成功！')
                    }else{
                        this.$message.error('推送失败')
                    }
                }
                
            })
        },
        clickExport(){
            this.$confirm('您是否确认导出当前列表数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                console.log("ooo");
                this.pararms = {
                    userId:this.id,
                    userName:this.inputVal1,
                    jobNumber:this.inputVal2,
                    instanceId:this.inputValID,
                    state:this.state,
                    startTime:this.startTime,
                    endTime:this.endTime,
                    pageNum:this.currentPage,
                    pageSize:this.pageSize,
                }
                axios({
                    method: "post",
                    url: `https://yrkq.yurun.com/api/ding/jiaban/AdminExportJiaBan`,
                    data:this.pararms,
                    responseType: 'blob'
                }).then((res)=>{
                    if(res){
                        // this.$message.success({ content: '导出成功！', key, duration: 2 });
                        this.$message.success('导出成功！');
                        let blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
                        let fileName = `加班数据` + '.xlsx'
                        if (window.navigator && window.navigator.msSaveBlob) {
                            window.navigator.msSaveBlob(blob, fileName)
                        }else {
                            let objectUrl = window.URL.createObjectURL(blob)
                            let downFile = document.createElement('a')
                            downFile.style.display = 'none'
                            downFile.href = objectUrl
                            downFile.download = fileName // 下载后文件名
                            document.body.appendChild(downFile)
                            console.dir(downFile);
                            downFile.click()
                            document.body.removeChild(downFile)
                            window.URL.revokeObjectURL(objectUrl) 
                        }
                    }else{
                        this.$message.error('导出失败！');
                    }
                })
            }).catch(() => {
                console.log("11");        
                // this.$message({
                //     type: 'info',
                //     message: '已取消导出'
                // });  
            });
        },
        getLoginInfo(){
            dd.ready(()=> {
                dd.runtime.permission.requestAuthCode({
                    corpId: "dingf3dc018dc6464b6435c2f4657eb6378f",
                    onSuccess: (resOne)=> {
                        api.getLoginApi(resOne.code).then(res2=>{
                            if(res2){
                                this.allLoading = false
                                this.id = res2.data.data.userid
                                this.getData()
                                api.getIsAdminApi(this.id).then(res=>{
                                    console.log(res.data,"bbb");
                                    if(res.data.data === 'Y'){
                                        this.showAdmin = true
                                    }else if(res.data.data === 'N'){
                                        this.showAdmin = false
                                    }
                                })
                            }else{
                                this.allLoading = true
                            }
                            // alert(JSON.stringify(res2.data.data.userid))
                            
                        })
                    },
                    onFail : function(err) {
                        alert(JSON.stringify(err))
                    }
                })
            })
        },
        // getLoginInfo(){
        //     this.allLoading = false
        //     this.id = '04003058'
        //     this.getData()
        //     api.getIsAdminApi(this.id).then(res=>{
        //         console.log(res.data,"bbb");
        //         if(res.data.data === 'Y'){
        //             this.showAdmin = true
        //         }else if(res.data.data === 'N'){
        //             this.showAdmin = false
        //         }
        //     })
        // },
        stateChangeHandler(i){
            console.log(i);
            this.state  = i
            if(i && i !== ''){
                this.state  = i
                this.getData()
            }else{
                this.state  = ''
                this.getData()
            }
        },

        
        getData(){
            this.showLoading = true
            api.getJiabanListApi(this.id,this.currentPage,this.pageSize,this.inputVal1,this.inputVal2,this.inputValID,this.startTime,this.endTime,this.state).then(res=>{
                console.log(res.data,"aaa");
                if(res){
                    this.showLoading = false
                    if(res.data.list.length > 0 && res.data.list !== []){
                        this.total = Number(res.data.total)
                        this.dataList = res.data.list
                        this.showKong = false
                    }else{
                        this.total = 0
                        this.currentPage = 1
                        this.pageSize = 20
                        this.dataList = []
                        this.showKong = true
                    }
                }else{
                    this.showLoading = true
                    this.dataList = []
                    this.total = 0
                    this.currentPage = 1
                    this.pageSize = 20
                }
            })
        },

        inputHandlerUserName(){//姓名
            this.inputVal1 = this.inputVal1.trim()
            this.getData()
        },
        inputHandlerJobNumber(){//工号
            this.inputVal2 = this.inputVal2.trim()
            this.getData()
        },
        inputHandlerJobNumberID(){//流程id
            this.inputValID = this.inputValID.trim()
            this.getData()
        },
        changeHandlerDate(i){//日期
            console.log(i);
            if(i && i !==null){
                let nowDate1 = new Date(i[0]);
                let nowYear1 = nowDate1.getFullYear();
                let nowMonth1 = nowDate1.getMonth()+1;
                let nowToday1 = nowDate1.getDate();
                this.startTime = nowYear1+'-'+this.fillZero(nowMonth1)+'-'+this.fillZero(nowToday1)
                console.log(this.startTime);
                let nowDate2 = new Date(i[1]);
                let nowYear2 = nowDate2.getFullYear();
                let nowMonth2 = nowDate2.getMonth()+1;
                let nowToday2 = nowDate2.getDate();
                this.endTime = nowYear2+'-'+this.fillZero(nowMonth2)+'-'+this.fillZero(nowToday2)
                console.log(this.endTime);
                this.getData()
            }else{
                this.startTime = ''
                this.endTime = ''
                this.getData()
            }
        },


        handleCurrentChange(i){
            this.currentPage = i
            this.getData()
        },
        handleSizeChange(i){
            this.currentPage = 1
            console.log(i);
            this.pageSize = i
            this.getData()
        },
        fillZero(str){
            var realNum;
            if(str<10){
                realNum	= '0'+str;
            }else{
                realNum	= str;
            }
            return realNum;
        },
        clickLis(item){
            console.log(item);
            this.choseObj = item
            this.showDrawer = true
            this.tuchengLoading = true
            
        },
        clickFujian(item){
            console.log(item);
            window.open(item.value)
        },
        handleCloseDrawer(){
            this.showDrawer = false
            this.infoObj = {}
            this.choseObj = {}
            console.log("111");
        }
   },
   
}
</script>
<style lang='less' scoped>
.all-box{
    width: 100%;
    height: 100%;
    background-color: #f4f6fc;
    padding: 30px 40px;
    .all-main-box-all{
        width: 100%;
        height: 100%;
    }
    .all-loading-box{
            width: 100%;
            height: 100%;
            background-color: #fff;
            position: relative;
            .content{
                width: 150px;
                height: 100px;
                text-align: center;
                line-height: 100px;
                position: absolute;
                top: 0px;
                right: 0;
                left: 0;
                bottom: 0;
                margin: auto;
                div {
                    width: 18px;
                    height: 18px;
                    background-color: blue;
                    border-radius: 100%;
                    display: inline-block;
                    animation: action 1.5s infinite ease-in-out;
                    animation-fill-mode: both;
                    margin-right: 12px;
                }
                .point1 {
                    animation-delay: -0.3s;
                }
                .point2 {
                    animation-delay: -0.1s;
                }
            }
            @keyframes action {
                0%, 80%, 100% {
                    transform: scale(0);
                }
                40% {
                    transform: scale(1.0);
                }
            }
        }
    .top-box{
        width: 100%;
        height: 110px;
        background-color: #fff;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 20px;
        .top-lis{
            width: 20%;
            height: 100%;
            display: flex;
            align-items: center;
            margin-right: 30px;
            justify-content: flex-start;
            .span-title{
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 22px;
                letter-spacing: 1px;
                margin-right: 15px;
            }
            .num-box{
                flex: 1;
                height: 55px;
                /deep/ .el-input-number__increase{
                    height: 50%;
                }
                /deep/ .el-input-number__decrease{
                    height: 50%;
                }
                /deep/ .el-input{
                    width: 100%;
                    height: 55px;
                    .el-input__inner{
                        height: 55px;
                        font-size: 22px!important;
                    }
                }
            }
            .date-box{
                flex: 1;
                height: 55px;
                /deep/ .el-range-separator{
                    display: flex;
                    align-items: center;
                }
                /deep/ .el-range-input{
                    font-size: 20px;
                    letter-spacing: 0.5px;
                }
            }
            .input-box{
                flex: 1;
                height: 55px;
                /deep/ .el-input__inner{
                    width: 100%;
                    height: 100%;
                    font-size: 21px!important;
                    letter-spacing: 0.5px;
                }
            }
            .search-ipt{
                flex: 1;
                height: 55px;
                /deep/ .el-input{
                    width: 100%;
                    height: 55px;
                    .el-input__inner{
                        width: 100%;
                        height: 100%;
                        font-size: 21px!important;
                        letter-spacing: 0.5px;
                    }
                }
            }
        }
        .top-lis-ts1{
            width: 15%;
        }
        .top-lis-ts2{
            width: 15%;
        }
        .top-lis-ts3{
            width: 26%;
        }
        .top-lis-ts4{
            width: 15%;
        }
        .send-box{
            padding: 12px 30px;
            background-color: #2970ff;
            margin-right: 30px;
            border-radius: 5px;
            color: #fff;
            letter-spacing: 2px;
            font-size: 20px;
            cursor: pointer;
            transition: all 0.2s linear;
        }
        
        .send-box:hover{
            background-color:#1890ff ;
        }
        .export-box{
            padding: 12px 35px;
            background-color: #007FFF;
            font-size: 22px;
            letter-spacing: 2px;
            color: #fff;
            border-radius: 6px;
            cursor: pointer;
            box-shadow: none;
            transition: all 0.2s linear;
        }
        .export-box:hover{
            box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 3px, rgba(0, 0, 0, 0.23) 0px 3px 3px;
        }
        
    }
    .bom-box{
        width: 100%;
        height: calc(100% - 140px);
        margin-top: 30px;
        background-color: #fff;
        border-radius: 10px;
        padding: 20px 30px;
        padding-bottom: 0px;
        .head-box{
            width: 100%;
            height: 100px;
            background-color: rgb(241,242,243);
            display: flex;
            .head-lis{
                width: 10%;
                height: 100%;
                border: 1px solid #ccc;
                border-left: none;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
                letter-spacing: 2.5px;
            }
            .head-lis1{
                width: 8%;
                border-left: 1px solid #ccc;
            }
            .head-lis1s{
                width: 15%;
            }
            .head-lis2{
                width: 15%;
            }
            .head-lis3{
                width: 15%;
            }
            .head-lis4{
                width: 15%;
            }
            .head-lis5{
                width: 9%;
            }
            .head-lis6{
                width: 15%;
            }
            .head-lis7{
                width: 8%;
            }
            
        }
        .list-box{
            width: 100%;
            height: calc(100% - 100px);
            .list-main-box{
                width: 100%;
                height: 100%;
                .list-main-table-box{
                    width: 100%;
                    height: calc(100% - 80px);
                    overflow: auto;
                }
                .list-main-table-box::-webkit-scrollbar{display:none} 
                .table-fenye{
                    width: 100%;
                    height: 80px;
                    background-color: #fff;
                    border-top: 1px solid #eaedf3;
                    border-bottom: 1px solid #eaedf3;
                    .fenye-box{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                    }
                    /deep/ .btn-prev{
                            color: rgba(0,0,0,.65);
                            border-color: #d9d9d9;
                            background-color: #fff;
                            border: 1px solid #d9d9d9;
                            border-radius: 5px;
                            padding: 0px!important;
                            width: 52px;
                            height: 50px;
                            line-height: 50px;
                            margin-left: 13px;
                            i{
                                font-size: 24px!important;
                                font-weight: 500;
                                color: rgba(0,0,0,.65);
                            }
                        }
                        /deep/ .number{
                            color: rgba(0,0,0,.65);
                            border-color: #d9d9d9;
                            background-color: #fff;
                            border: 1px solid #d9d9d9;
                            border-radius: 5px;
                            padding: 0px!important;
                            min-width: 50px;
                            padding: 0 6px!important;
                            height: 50px;
                            line-height: 50px;
                            font-size: 21px!important;
                            font-weight: 500;
                            color: rgba(0,0,0,.65);
                            margin-left: 13px;
                            transition: all 0.2s linear;
                        }
                        /deep/ .number:hover{
                            border-color: #1890ff;
                            color: #1890ff;
                        }
                        /deep/ .active{
                            border-color: #1890ff;
                            color: #1890ff; 
                        }
                        /deep/ .btn-quickprev{
                            width: 52px;
                            height: 50px;
                            line-height: 50px;
                        }
                        /deep/ .btn-quicknext{
                            width: 52px;
                            height: 50px;
                            line-height: 50px;
                        }
                        /deep/ .btn-next{
                            color: rgba(0,0,0,.65);
                            border-color: #d9d9d9;
                            background-color: #fff;
                            border: 1px solid #d9d9d9;
                            border-radius: 5px;
                            padding: 0px!important;
                            width: 52px;
                            height: 50px;
                            line-height: 50px;
                            margin-left: 13px;
                            i{
                                font-size: 21px!important;
                                font-weight: 500;
                                color: rgba(0,0,0,.65);
                            }
                        }
                        /deep/ .el-pagination__jump{
                            width: 140px;
                            height: 50px;
                            line-height: 50px;
                            display: flex;
                            align-items: center;
                            justify-content: space-around;
                        }
                        /deep/ .el-input{
                            width: 60px;
                            height: 50px;
                            line-height: 50px;
                            padding: 0px!important;
                        }
                        /deep/ .el-pagination__editor{
                            margin: 0px!important;
                        }
                        /deep/ .el-input__inner{
                            width: 60px;
                            height: 50px;
                            line-height: 50px;
                            padding: 0px!important;
                            font-size: 21px!important;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        /deep/ .el-pagination__total{
                            font-size: 21px;
                        }
                        /deep/ .el-pagination__jump{
                            font-size: 21px;
                        }
                        /deep/ .el-pagination__sizes{
                            width: 140px;
                            height: 50px!important;
                            .el-select{
                                height: 100%;
                                .el-input{
                                    height: 100%;
                                    .el-input__inner{
                                        width: 140px;
                                        height: 100%;
                                        font-size: 22px!important;
                                    }
                                }
                            }
                        }
                }
                .list-lis-box{
                    width: 100%;
                    height: 64px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    background-color: #fff;
                    transition: all 0.2s linear;
                    border-bottom: 1px solid #ccc;
                    .lsi-box-son{
                        width: 100%;
                        height: 100%;
                        font-size: 20px;
                        color: #444;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        letter-spacing: 2px;
                    }
                    .son-ss1{
                        width: 8%;
                    }
                    .son-ss1s{
                        width: 15%;
                        padding: 8px 25px;
                            justify-content: flex-start;
                            span{
                                white-space: nowrap; //禁止换行
                                overflow: hidden;
                                text-overflow: ellipsis; 
                            }
                    }
                    .son-ss2{
                        width: 15%;
                        padding: 8px 25px;
                            justify-content: flex-start;
                            span{
                                white-space: nowrap; //禁止换行
                                overflow: hidden;
                                text-overflow: ellipsis; 
                            }
                    }
                    .son-ss3{
                        width: 15%;
                    }
                    .son-ss4{
                        width: 15%;
                    }
                    .son-ss5{
                        width: 9%;
                    }
                    .son-ss6{
                        width: 15%;
                        padding: 8px 25px;
                            justify-content: flex-start;
                            span{
                                white-space: nowrap; //禁止换行
                                overflow: hidden;
                                text-overflow: ellipsis; 
                            }
                    }
                    .son-ss7{
                        width: 8%;
                        .daishenpi{
                                color: #4e6ef2;
                            }
                            .tongguo{
                                color: rgb(41, 189, 100);
                            }
                            .butongguo{
                                color: red;
                            }
                            .bohui{
                                color: red;
                            }
                            .jinxingzhong{
                                // color: #6272D6;
                                color: rgb(79,20,127);
                            }
                    }

                    
                }
                .list-lis-box:hover{
                    background-color: rgb(234,235,236);
                }
            }
            
            .kong-box{
                width: 100%;
                height: 100%;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-bottom: 160px;
                .img-kong{
                    width: 400px;
                    height: 300px;
                }
                p{
                    text-align: center;
                    line-height: 60px;
                    font-size: 25px;
                    color: #000;
                    letter-spacing: 4px;
                }
            }
        }
        .loading-box{
            width: 100%;
            height: calc(100% - 100px);
            background-color: #fff;
            position: relative;
            .content{
                width: 150px;
                height: 100px;
                text-align: center;
                line-height: 100px;
                position: absolute;
                top: 0px;
                right: 0;
                left: 0;
                bottom: 0;
                margin: auto;
                div {
                    width: 18px;
                    height: 18px;
                    background-color: blue;
                    border-radius: 100%;
                    display: inline-block;
                    animation: action 1.5s infinite ease-in-out;
                    animation-fill-mode: both;
                    margin-right: 12px;
                }
                .point1 {
                    animation-delay: -0.3s;
                }
                .point2 {
                    animation-delay: -0.1s;
                }
            }
            @keyframes action {
                0%, 80%, 100% {
                    transform: scale(0);
                }
                40% {
                    transform: scale(1.0);
                }
            }
        }
    }
    .tanchu-box{
        // width: ;
        /deep/ .el-drawer{
             width: 27%!important;
            .el-drawer__header{
                margin-bottom: 0px;
                width: 100%;
                height: 80px;
                padding: 0px 25px;
                border-bottom: 1.5px solid rgb(241,241,241);
                span{
                    font-size: 27px;
                    color: #000;
                    letter-spacing: 2px;
                }
                .el-drawer__close-btn{
                    font-size: 30px;
                    color: #000;
                }
            }
            .draw-main{
                width: 100%;
                height: 100%;
                iframe{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
</style>